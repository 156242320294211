#services {
  .root {
    position: relative;
    padding: spacing(5, 0);
    @include breakpoints-up(sm) {
      padding: spacing(10, 0);
    }
    .theme--dark & {
      @include bg-color-fade($palette-primary-dark, 0.4)
    }
    .theme--light & {
      @include bg-color-lighten($palette-primary-light, 0.8)
    }
  }
  
  .carousel-header {
    position: relative;
    z-index: 3;
    @include breakpoints-up(sm) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    @include breakpoints-down(xs) {
      text-align: center;
    }
  }

  .view-all {
    display: flex;
    margin-bottom: 50px;
    @include breakpoints-up(lg) {
      @include margin-right(160px);
    }
    @include breakpoints-up(sm) {
      font-size: 24px;
    }
    @include breakpoints-down(sm) {
      margin-bottom: 32px
    }
    @include breakpoints-down(xs) {
      justify-content: center;
    }
    .icon {
      @include margin-left($spacing1);
      @include rtl-flip;
      @include breakpoints-up(sm) {
        font-size: 36px;
      }
    }
  }

  .carousel-handle {
    height: 420px;
    position: relative;
    z-index: 10
  }

  .carousel-wrap {
    position: absolute;
    z-index: 2;
    width: 100%;
    overflow: hidden
  }

  .item {
    padding: spacing(0, 1);
    max-width: 260px;
    &:focus {
      outline: none
    }
  }

  .carousel-prop {
    @include breakpoints-down(md) {
      display: none;
    }
    div {
      width: 250px
    }
  }

  .floating-artwork {
    position: absolute;
    width: 100%;
    left: 0;
    top: spacing(5);
    @include breakpoints-up(lg) {
      left: $spacing3;
    }
    @media (min-width: 1400px) {
      left: $spacing10
    }
    .artwork {
      @include breakpoints-up(md) {
        margin-right: $spacing5
      }
      @include breakpoints-up(lg) {
        float: right;
      }
      @media (min-width: 1400px) {
        margin-right: $spacing5 * -1
      }
    }
  }

  .arrow {
    @include padding-left($spacing15);
    [dir="rtl"] & {
      direction: rtl;
      button i {
        transform: rotate(180deg)
      }
    }
    [dir="ltr"] & {
      direction: ltr
    }
    margin-top: 100px;
    position: relative;
    z-index: 10;
    button {
      @include palette-background-paper;
      margin: calc($spacing1 / 2);
      &:hover {
        @include palette-background-default
      }
      i {
        .theme--dark & {
          color: $palette-primary-light
        }
        .theme--light & {
          color: $palette-primary-dark
        }
      }
    }
    @media (max-width: 1279px) {
      display: none
    }
    @include breakpoints-down(md) {
      display: none;
      margin-top: 560px
    }
  }
}
