.bg-deco {
  .form-deco, .page-deco {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
  }

  .big, .small {
    border-radius: 40px;
    transform: rotate(45deg);
    position: absolute;
    opacity: 0.2
  }

  .small {
    width: 280px;
    height: 280px;
    @include use-theme(background-color, $palette-primary-light, $palette-primary-main);
  }

  .big {
    width: 400px;
    height: 400px;
    @include use-theme(background-color, $palette-secondary-light, $palette-secondary-main);
  }

  .left-deco, .right-deco {
    position: absolute;
  }

  .form-deco {
    .left-deco {
      left: -200px;
      top: 400px;
      .big {
        left: 80px;
        top: 200px;
      }
      .small {
        left: 0px;
        top: 130px;
      }
    }

    .right-deco {
      right: -50px;
      top: -300px;
      .big {
        right: 50px;
        top: 190px;
      }
      .small {
        right: 0px;
        top: 140px;
      }
    }
  }

  .page-deco {
    .left-deco {
      left: -200px;
      top: -200px;
      .big {
        left: 80px;
        top: 200px;
      }
      .small {
        left: 0px;
        top: 130px;
      }
    }

    .right-deco {
      right: -50px;
      top: 400px;
      .big {
        right: 50px;
        top: 190px;
      }
      .small {
        right: 0px;
        top: 140px;
      }
    }
  }
}
