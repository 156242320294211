.footer-deco {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  @include breakpoints-down(sm) {
    display: none;
  }
  .big, .small {
    border-radius: 20px;
    transform: rotate(45deg);
    position: absolute;
    opacity: 0.2
  }

  .small {
    width: 140px;
    height: 140px;
    @include use-theme(background-color, $palette-primary-light, $palette-primary-dark);
  }

  .big {
    width: 200px;
    height: 200px;
    @include use-theme(background-color, $palette-secondary-light, $palette-secondary-dark);
  }

  .left-deco {
    position: absolute;
    left: 0;
    .big {
      left: 80px;
      top: 200px;
    }
    .small {
      left: 0px;
      top: 130px;
    }
  }

  .right-deco {
    position: absolute;
    right: 0;
    .big {
      right: 50px;
      top: 190px;
    }
    .small {
      right: 0px;
      top: 140px;
    }
  }
}

