.company-logo
  .root {
    display: flex;
    @include breakpoints-up(sm) {
      justify-content: center;
    }
    @include breakpoints-down(xs) {
      overflow-x: auto
    }
    img {
      height: 64px;
      margin: $spacing4;
      filter: grayscale(1) contrast(0.5) brightness(1.5);
      transition: all 0.3s ease-out;
      &:hover {
        filter: none
      }
    }
  }
